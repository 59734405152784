@import url('https://fonts.googleapis.com/css?family=Noto+Serif|Roboto&display=swap');

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F4F4F4;
  color: #434343;
}

* {
  box-sizing: border-box;
}

h1,h2,h3,h4,h5,h6 {
  font-family: "Noto Serif", sans-serif;
  font-weight: 400;
  margin:0;
  line-height: 1.4;
}

p {
  font-size: 18px;
  line-height: 1.6;
}

h2 {
  @media only screen and (max-width: 750px){
    font-size: 20px!important;
  }
}

input, textarea {
  padding: 10px;
  font-size: 16px;
  border:1px solid rgb(224, 224, 224);
  color:rgb(51, 51, 51);
  resize: none;
}

.btn {
  background-color: #2E3A75;
  color:white;
  font-family: "Noto Serif", sans-serif;
  font-size: 16px;
  padding:10px 20px;
  border: 0;
  -webkit-appearance: none;
  text-decoration: none;
  cursor: pointer;
  transition: .2s;
  &:hover {
    background-color: darken(#2E3A75,10%);
  }
}

.alt-btn {
  border:1px solid rgb(224, 224, 224);
  color:rgb(51, 51, 51);
  background-color: white;
  font-family: "Noto Serif", sans-serif;
  font-size: 16px;
  padding:10px 20px;
  -webkit-appearance: none;
  text-decoration: none;
  cursor: pointer;
  transition: .2s;
  &:hover {
    background-color: darken(white,5%);
  }
}

.header-background {
  background: #2e3a75;
  padding: 20px 40px;
}

.article {
  max-width: 750px;
    justify-self: center;
    text-align: center;
    img {
      width:100%;
    }
    ul,ol,p,li,a {
      font-size: 18px;
    }
    ul,ol {
      text-align: left;
      li {
        padding:5px 0px;
      }
    }
}

.service-slider {
  display: grid;
  grid-template-rows: auto;
  width:100%;
  max-width: 900px;
  .carousel {
    border-radius: 10px;
  }
  img {
    width:100%;
    max-width: 900px;
    border-radius: 10px;
  }
}

.header-overlay {
  position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: grid;
    align-content: center;
    justify-content: center;
    background: #2e3a75a1;
}


.post-preview {
  text-decoration: none;
  color:rgb(51, 51, 51);
  transition: .2s;
  margin-bottom: 100px;
  &:hover {
    opacity: .6;
  }
  div {
    width:100%;
    height:200px;
    background-size: cover;
    background-position: center;
    border-radius: 10px;
  }
  h4 {
    margin-top:16px;
    font-size: 24px;
    small {
      opacity: .8;
    }
  }
}

.sub-nav {
  display: flex;
  list-style: none;
  padding-left: 0px;
  justify-content: center;
  white-space: nowrap;
  overflow-x: auto;
  margin: 0;
    a {
      color:#535353;
      text-decoration: none;
      padding:15px 20px;
      transition: .2s;
      &:hover {
        background-color: rgb(250, 250, 250);
      }
    }
    .active {
      background-color: rgb(245, 245, 245);
    }
    @media only screen and (max-width: 1150px){
      justify-content: unset;
    }
}

.mega-heading {
  font-size: 42px;
  @media only screen and (max-width: 750px){
    font-size: 22px;
  }
}

.sub-heading {
  font-family: "Roboto", sans-serif;
  opacity: .6;
}

.container {
  display: grid;
  grid-template-columns: minmax(0,1fr) minmax(0,1600px) minmax(0,1fr);
  &-center {
    grid-column: 2;
    background-color: white;
    position: relative;
    overflow-x: hidden;
    box-shadow: 0 6px 16px 0 rgba(0,0,0,.05), 0 3px 6px 0 rgba(0,0,0,.01);
  }
}

.content-container {
  min-height: 100vh;
}

.padding {
  @for $i from 0 to 100 {
      &-#{$i} { padding: 0px + $i!important };
      &-top-#{$i} { padding-top: 0px + $i!important };
      &-bottom-#{$i} { padding-bottom: 0px + $i!important };
      &-left-#{$i} { padding-left: 0px + $i!important };
      &-right-#{$i} { padding-right: 0px + $i!important };
  }
}

.margin {
  @for $i from 0 to 100 {
      &-#{$i} { margin: 0px + $i!important };
      &-top-#{$i} { margin-top: 0px + $i!important };
      &-bottom-#{$i} { margin-bottom: 0px + $i!important };
      &-left-#{$i} { margin-left: 0px + $i!important };
      &-right-#{$i} { margin-right: 0px + $i!important };
  }
}

.content-layout {
  display: grid;
  grid-gap: 12.5vh;
  @media only screen and (max-width: 750px){
    padding-left: 30px!important;
    padding-right: 30px!important;
  }
}

.page-banner {
  background-color: #2e3a75;
  background-size: cover;
  padding-top:100px;
  padding-bottom: 100px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  color:white;
  @media only screen and (max-width: 750px){
    padding-top:80px;
    padding-bottom: 80px;
  }
}

.page-image-banner {
  position: relative;
  background-color: #2e3a75;
  background-size: cover;
  height: 500px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color:white;
  @media only screen and (max-width: 750px){
    height: 300px;
  }
}

.footer-contact-bar {
  border-top: 1px solid rgb(235, 235, 235);
  background-color: #fafafa;
  @media only screen and (max-width: 1040px){
    grid-template-columns: repeat(1,1fr)!important;
    grid-gap: 40px;
    text-align: center;
    > *:last-child{
      justify-self: unset!important;
    }
  }
  @media only screen and (max-width: 750px){
    padding: 30px 20px!important;
  }
}
.footer {
  border-top: 1px solid rgb(235, 235, 235);
  @media only screen and (max-width: 750px){
    padding: 30px 20px!important;
  }
  .logo {
    height:30px;
    @media only screen and (max-width: 750px){
      height:24px;
    }
  }
  &-note {
    color:rgb(65, 65, 65);
    * {
      color:rgb(63, 63, 63);
    }
    @media only screen and (max-width: 750px){
      font-size: 12px;
      margin-top:20px!important;
    }
  }
  ul {
    list-style: none;
    padding:0;
    li:not(:last-child) {
      margin-bottom:15px;
    }
    a {
      color:#434343;
      text-decoration: none;
      font-size: 18px;
    }
  }
}

.ui-fact {
    background-color: #fafafa;
    border: 1px solid rgba(235, 235, 235, 0.9);
    padding: 24px;
    p, li {
      font-size: 14px;
    }
    &-details {
      border-top: 1px solid rgba(235, 235, 235, 0.9);
      margin-top: 24px;
      padding-top: 24px;
    }
}

.header {
  position: absolute;
  z-index:2;
  width:100%;
  display:grid;
  grid-template-columns: auto auto;
  align-items: center;
  @media only screen and (max-width: 1100px){
    padding: 16px 20px !important;
    &-relative {
      padding: 16px 20px !important;
    }
  }
  &-mobile-trigger {
    justify-self: flex-end;
    display: none;
    @media only screen and (max-width: 945px){
      display: unset;
    }
    i {
      padding:10px 15px;
      background-color: rgba(255,255,255,0.1);
      color:white;
      font-size: 22px;
      cursor: pointer;
    }
  }
  padding: 30px 40px;
  &-relative {
    position: relative !important;
    border-bottom: 0.5px solid #f0f0f0;
    background: white;
    padding: 30px 40px;
    .header-mobile-trigger {
      i {
        
        background-color: rgba(0,0,0,0.1)!important;
        color:#434343!important;
      }
    }
    .search {
      background-color: #f4f4f4!important;
    }
    li {
      a {
        color:rgb(83, 83, 83)!important;
        &.active {
          background-color: rgb(46, 58, 117) !important;
    color: white!important;
        }
        &:hover:not(.active) {
          background-color: rgb(235, 235, 235) !important;
        }
      }
      input {
        color: black !important;

        &::placeholder {
          color: black;
        }
      }
    }
  }
  .logo {
    height:30px;
    @media only screen and (max-width: 1100px){
      height:24px;
    }
  }
  &-navigation {
    justify-self: flex-end;
    display: flex;
    list-style: none;
    padding:0;
    .search {
      display: flex;
      align-items: center;
      padding-left:16px;
      a:hover {
        background-color: transparent;
      }
      input::placeholder {
        opacity: .5;
      }
    }
    @media only screen and (max-width: 945px){
      display:none;
      flex-direction: column;
      width:100%;
      position: absolute;
      top:58px;
      background-color: #f6f6f6;
      padding:0!important;
      border-bottom: 1px solid lightgrey;
    }
    li {
      
      &:not(:last-child){
        margin-right:10px;
        @media only screen and (max-width: 1100px){
          margin-right:5px;
        }
        @media only screen and (max-width: 945px){
          margin-right:0px;
        }
      }
      a {
        color:white;
        text-decoration: none;
        font-size: 17px;
        padding: 8px 10px;
        transition: .25s;
        &:hover, &.active {
          background-color: rgba(255,255,255,0.1);
        }
        @media only screen and (max-width: 945px){
          display: block;
          border-top: 1px solid rgb(226, 226, 226);
          padding: 10px 20px;
          font-size: 15px;
          i {
            opacity: .3;
          }
          color: #535353;
        }
      }
    }
  }
}

.home {
  &-banner {
    height: 800px;
    background-color: #7496b2;
    background-image: url("https://i.imgur.com/Wni5XrV.jpg");
    background-size: cover;
    padding-top:220px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-position: bottom;
    @media only screen and (max-width: 750px){
      height:550px;
      // background-image: unset;
      padding-top:120px;
      padding-bottom: 70px;
    }
    &-service-selector {
      display: flex;
    align-items: center;
    box-shadow: 0 6px 16px 0 rgba(0,0,0,.1), 0 3px 6px 0 rgba(0,0,0,.03);
    margin-top: 50px;
    padding: 16px 20px;
    background: white;
    border-radius: 10px;
    @media only screen and (max-width: 650px){
      flex-direction: column;
      select {
        margin-left: 0!important;
        margin-top:20px;
      }
    }
    select {
      -webkit-appearance: none;
    border: 0;
    font-size: 18px;
    color: #434343;
    font-family: "Noto Serif", sans-serif;
    /* background: transparent; */
    padding: 8px 14px;
    margin-left: 12px;
    border: 1px solid #eeeeee;
    }
    }
    h1 {
      color:white;
      max-width: 1200px;
      padding: 0 5vw;
    }
    h2 {
      color:white;
      opacity: .6;
      font-size: 28px;
      @media only screen and (max-width: 750px){
        margin-top: 10px!important;
      }
    }
  }
}

.breaker-squares {
  display: flex;
  *:not(:last-child){
    margin-right:5px;
  }
  * {
    width: 12px;
    height: 12px;
    &:nth-child(1){
      background-color: #2E3A75;
    }
    &:nth-child(2){
      background-color: #4997D9;
    }
    &:nth-child(3){
      background-color: #2E3A75;
    }
  }
}

.colour-cta {
  color:#4997D9;
}

li {
  line-height: 1.5;
}

.xyz {
  > * {
    display: grid;
  }
}

.col {
  display: grid;
  @for $i from 1 to 13 {
    &-#{$i} {
      display: grid;
      grid-template-columns: repeat($i, 1fr);

      @media only screen and (max-width: 750px) {
        grid-template-columns: 1fr;
      }

      &-min {
        display: grid;
        grid-template-columns: repeat($i, min-content);
      }

      &-max {
        display: grid;
        grid-template-columns: repeat($i, max-content);
      }
    }
    &-start-#{$i} {
      grid-column-start: $i;
    }
    &-end-#{$i} {
      grid-column-end: $i + 1;
    }
    &-start-md-#{$i} {
      grid-column-start: $i;

      @media only screen and (max-width: 750px) {
        grid-column-start: 1;
      }
    }
    &-end-md-#{$i} {
      grid-column-end: $i + 1;

      @media only screen and (max-width: 750px) {
        grid-column-end: 1;
      }
    }
  }
}

.grid {
  display: grid;
  &-center {
    &-top {
      display: grid;
      align-items: start;
      justify-content: center;
    }
    &-center {
      display: grid;
      align-items: center;
      justify-content: center;
    }
    &-bottom {
      display: grid;
      align-items: end;
      justify-content: center;
    }
  }
  &-left {
    &-top {
      display: grid;
      align-items: start;
      justify-content: left;
    }
    &-center {
      display: grid;
      align-items: center;
      justify-content: left;
    }
    &-bottom {
      display: grid;
      align-items: end;
      justify-content: left;
    }
  }
  &-right {
    &-top {
      display: grid;
      align-items: start;
      justify-content: right;
    }
    &-center {
      display: grid;
      align-items: center;
      justify-content: right;
    }
    &-bottom {
      display: grid;
      align-items: end;
      justify-content: right;
    }
  }
}

.gap {
  @for $i from 1 to 100 {
    &-#{$i} {
      gap: 0px + $i;
    }
  }
}

.services-card-container {
  max-width: 1000px;
  justify-self: center;
  width:100%;
  .services-card {
    background-color: #fafafa;
    border: 1px solid rgba(235, 235, 235, .9);
    text-align: center;
    padding: 90px 15px;
    text-decoration: none;
    color:#434343;
    transition:.25s;
    cursor: pointer;
    h2 {
      font-size: 18px;
    }
    i {
      font-size: 48px;
      margin-bottom:20px;
      color:#2E3A75;
    }
    &:hover {
      background-color: #4997D9;
      border: 1px solid transparent;
      color:white;
      i {
        color:rgba(255,255,255,.6)
      }
    }
  }
}

@media only screen and (max-width: 900px){

}

@media only screen and (max-width: 945px){
  .mobile-hide {
    display: none!important;
  }
  
  .mobile-show {
    display: unset!important;
  }
}

.login {
  .back {
    position: absolute;
    top:30px;
    left:30px;
    color: rgb(51,51,51);
    text-decoration: none;
    @media only screen and (max-width: 700px){
      top:16px;
      left:16px;
    }
  }
  padding:80px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .logo {
    height:24px;
  }
  @media only screen and (max-width: 700px){
    padding:30px;
    align-items: unset;
    margin-top:30px;
    justify-content: unset;
    > div {
      text-align: center;
      h1 {
        font-size: 24px;
      }
    }
  }
}

.search {
  border-radius: 10px;
    position: relative;
    width: 500px;
    max-width: 90%;
    margin-top:20px;
    input {
      border-radius: 10px;
    padding: 20px;
    min-width: 100%;
    font-size: 20px;
    }
    i {
      position: absolute;
    right: 19px;
    color: #2e3a75;
    font-size: 22px;
    top: 20px;
    }
}